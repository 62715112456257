.footer-row {
  text-align: center;
  justify-content: center;
}

.footer {
  display: flex;
  font-size: 20px;
  height: 50px;
  text-decoration: none;
}

.footer-contents {
  margin: auto;
}

.tinystacks-logo {
  height: 30px;
  background: #111111;
  padding: 5px;
  border-radius: 4px;
}