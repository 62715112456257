


.premium-page {
  display: flex;
  flex-direction: column;
  text-align: center;
}


.premium-picture {
  height: 200px;
  width: 200px;
  margin: auto;
}


.premium-subheader {
  font-size: 24px;
}

@media (max-width: 400px) {
  .premium-header {
    font-size: 20px;
  }
  .premium-subheader {
    font-size: 18px;
  }
}


.premium-plan-box {
  border-radius: 4px;
  box-shadow: 0 0px 30px 0 rgb(150 150 150 / 50%);
  padding: 30px 15px;
  margin: auto;
  display: flex;
  max-width: 450px;
  width: 100%;
  flex-direction: column;
}

.radio-button-container {
  display: flex;
  margin: 5px;
  font-size: 20px;
  align-items: center;
  cursor: pointer;
}

.radio-button {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.plans-text {
  font-size: 24px;
  text-align: left;

}

.sign-up-button {
  background-color: #26ABF6;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 20px;
  padding: 5px 10px;
  margin: 30px auto 0;
  width: 50%;
}

.sign-up-button:hover {

  color: #FFFFFF;
}

.premium-page-feature-container {
  margin-top: 30px;
  padding-top: 60px;
  border-top: solid 1px #eeeeee;
  padding-bottom: 120px;
}

.feature-header {
  text-align: center;
}

.premium-table {
  border-top: solid 1px #eeeeee;
  border-bottom: solid 1px #eeeeee;
  margin: auto;
  font-size: 20px;
}

.feature-table-feature-header, .feature-table-free-header, .feature-table-premium-header {
  font-size: 22px;
  padding: 10px 15px 0;
}

.feature-table-free-header, .feature-table-premium-header {
  text-align: center;
  border-left: solid 2px #eeeeee;
}

.premium-table-feature-row-header {
  border-bottom: solid 2px #eeeeee;
}

.premium-table-feature-row {
  border-bottom: solid 1px #eeeeee;
}

.feature-table-feature-name, .feature-table-free-feature, .feature-table-premium-feature {
  padding: 10px 0;
}

.feature-icon-mark {
  height: 24px;
}

.feature-table-feature-name {
  padding-right: 120px;
  padding-left: 15px;
}

@media (max-width: 692px) {
  .feature-table-feature-name {
    padding-right: 5px;
    font-size: 16px;
  }

  .feature-table-feature-header, .feature-table-free-header, .feature-table-premium-header {
    padding: 10px 10px 0;
    font-size: 18px;
  }
}

.feature-table-free-feature, .feature-table-premium-feature {
  text-align: center;
  border-left: 2px solid #eeeeee;
}

.more-features {
  background-color: #eeeeee;
}

.thank-you-message {
  font-size: 18px;
}
