.navbar-container {
  max-width: 1040px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
  justify-content: space-between;
  padding: 15px 30px;

}

@media (min-width: 576px) {
  .navbar-container {
    max-width: 540px;
    justify-content: space-between;
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media (min-width: 768px) {
  .navbar-container {
    max-width: 720px;
    justify-content: space-between;
    padding-left: 45px;
    padding-right: 45px;

  }
}

@media (min-width: 992px) {
  .navbar-container {
    max-width: 960px;
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media (min-width: 1200px) {
  .navbar-container {
    padding-right: 0;
    max-width: 1040px;
  }
}

.profile {
  display: flex;
  margin-right: 15px;
  cursor: pointer;
}

.profile:hover .profile-icon {
  background-color: #FFFFFF;
  border-radius: 20px;
  padding: 1px;
  font-weight: bold;
  filter: invert(1);
}

.profile-icon {
  margin: auto;
  height: 24px;
  margin-right: 5px;
  cursor: pointer;
}

.profile-text {
  margin: auto;
  cursor: pointer;
}

.navbar-brand {
  align-items: center;
  cursor: pointer;
  display: flex !important;
}

.brand-icon {
  height: 24px;
}

.brand-text {
  margin: auto;
}
