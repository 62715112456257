@import "font-classes.css";

body {
  font-family: "Quicksand";
}

.main-app-container {
  padding-top: 130px;
}

.download-icon {
  height: 20px;
  padding-bottom: 2px;
}

.leader {
  margin: 30px auto;
  max-height: 180px;
  text-align: center;
  width: 100%;
}

.leader-img {
  max-width: 100%;
  max-height: 180px;
}



.font-size {
  border: solid 1px;
  border-radius: 4px;
  margin-left: 5px;
  padding-left: 5px;
}

.font-sizes-container {
  display: flex;
}

.font-size-button {
  background-color: #FFFFFF;
  border: solid black 2px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
  padding: 0 5px;
}

.font-size-button.selected {
  background-color: #000000;
  color: #FFFFFF!important;
}

.font-style {
  background-color: #FFFFFF;
  border: solid 1px;
  border-radius: 4px;
  margin-left: 5px;
}

.spinner-border.spinner {
  height: 5em;
  width: 5em;
  margin: auto;
  border: solid 1.25em #FFFFFF;
  border-right-color: transparent;
}

label {
  font-family: "Quicksand";
  font-size: 18px;
  font-weight: 900;
}

@media (max-width: 991px) {
  .font-style {
    width: 140px
  }
}

