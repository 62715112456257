.login-btn, .premium-btn, .logout-btn {
  border-radius: 0;
  color: #FFFFFF;
  padding: 0.5rem 1rem;
  font-size: 0.965625rem;
  line-height: 1.5;
  margin-left: 0;
  margin-top: 15px;
  background-color: #000000;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
}

.login-btn:hover, .premium-btn:hover, .logout-btn:hover{
  background-color: #777777;
  font-weight: bold;

}

.buttons-when-logged-out {
  display: flex;
  flex-direction: column;
}

@media (min-width: 576px) {
  .buttons-when-logged-out {
    display: flex;
    flex-direction: column;
  }

  .login-btn, .premium-btn, .logout-btn {
    margin-left: 0;
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  .buttons-when-logged-out {
    display: flex;
    flex-direction: column;
  }

  .login-btn, .premium-btn, .logout-btn {
    margin-left: 0;
    margin-top: 15px;
  }
}

@media (min-width: 992px) {
  .buttons-when-logged-out {
    display: block;
  }
  .login-btn, .premium-btn, .logout-btn {
    margin-left: 15px;
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .buttons-when-logged-out {
    display: block;
  }

  .login-btn, .premium-btn, .logout-btn {
    margin-left: 15px;
    margin-top: 0;
  }
}
