.contact-page {
  flex-direction: column;
  margin: auto;
  max-width: 800px;
}

.contact-message {
  font-size: 24px;
}

.contact-link-twiiter,  .contact-link-email {
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
}

.link-container {
  display: flex;
}

.link-icon {
  height: 32px;
  margin-right: 5px;
  align-self: center;
}
