@font-face {
    font-family: "Quicksand";
    src: local("Quicksand"), url("../static/fonts/quicksand.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Arial";
    src: local("Arial"), url("../static/fonts/arial.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Arial Narrow";
    src: local("Arial Narrow"), url("../static/fonts/arialn.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Baskerville";
    src: local("Baskerville"), url("../static/fonts/baskerville.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Bodoni";
    src: local("Bodoni"), url("../static/fonts/bodoni.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Merriwather Sans";
    src: local("Merriwather Sans"), url("../static/fonts/merriweathersans.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Helvetica";
    src: local("Helvetica"), url("../static/fonts/helvetica.otf") format("opentype");
    font-display: swap;
}

@font-face {
    font-family: "Raleway";
    src: local("Raleway"), url("../static/fonts/raleway.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Times New Roman";
    src: local("Times New Roman"), url("../static/fonts/times.ttf") format("truetype");
    font-display: swap;
}

/* FONT CLASSES */
.arial {
    font-family: "Arial";
}

.arialn {
    font-family: "Arial Narrow";
}

.baskerville {
    font-family: "Baskerville";
}

.bodoni {
    font-family: "Bodoni";
}

.merriweathersans {
    font-family: "Merrieather Sans";
}

.helvetica {
    font-family: "Helvetica";
}

.raleway {
    font-family: "Raleway";
}

.times {
    font-family: "Times New Roman";
}